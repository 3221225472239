<template>
  <div class="signa-list-in-list">
    <BaseList
      :list-query="signa"
      :filter="signum => !onlyPrimary || signum.inscriptions.length > 0"
      class="signas-list"
      locale-section="pages.inscriptions"
    >
      <template
        v-slot:element="{
          element: { signum2, inscriptions }
        }"
      >
        <router-link
          :to="{
            name: 'inscription',
            params: { id: inscriptions[0].inscription.id }
          }"
          >{{ signum1 }} {{ signum2 }}</router-link
        >
        <template v-if="inscriptions[0]">
          <TeiWordCountWarning
            v-for="item in referencesAndLanguages(inscriptions[0].inscription)"
            :key="item.reference + item.language"
            :tei-primary="
              getReadingTeiTextByReference(
                inscriptions[0].inscription,
                item.reference
              )
            "
            :tei-reference="
              getInterpretationTeiTextByReferenceAndLanguage(
                inscriptions[0].inscription,
                item.reference,
                item.language
              )
            "
            :reference-key="item.reference + '/' + item.language"
          />
        </template>
      </template>
    </BaseList>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "InscriptionsList",
  components: {
    BaseList: () => import("@/components/BaseList"),
    TeiWordCountWarning: () =>
      import("@/pages/inscriptions/detail/TeiEditor/TeiWordCountWarning")
  },
  props: {
    signum1: { type: String, default: "" },
    onlyPrimary: { type: Boolean, default: true }
  },
  data() {
    return {
      signa: {
        query: gql`
          query signa($signum1: String!) {
            list: signa(signum1: $signum1) {
              name: signum2
              signum1 {
                signum1
              }
              signum2
              inscriptions(onlyCanonical: true) {
                inscription {
                  id
                  ornamental
                  runeTypes {
                    runeType
                    category
                  }
                  runetypeDescription
                  runetypeDescriptionLang {
                    language
                    sv
                    en
                  }
                  readings {
                    reference
                    teiText
                  }
                  interpretations {
                    readingReference
                    teiText
                    language {
                      language
                      sv
                      en
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          signum1: this.signum1
        }
      },
      readingReferences: [
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "AA",
        "AE",
        "OE"
      ],
      runicOrder: [
        "FVN",
        "URN",
        "RSV",
        "RDA",
        "FGU",
        "FSV",
        "FDA",
        "FÖN",
        "FNO",
        "FIS",
        "NON",
        "DSV",
        "OVD",
        "NSV",
        "LAT",
        "und",
        "NFS"
      ]
    };
  },
  methods: {
    references(inscription) {
      return inscription.readings
        .map(({ reference }) => reference)
        .slice() // to avoid sorting in-place
        .sort((r0, r1) => r0.localeCompare(r1, "sv"));
    },
    referencesAndLanguages(inscription) {
      return inscription.interpretations
        .map(({ readingReference, language }) => ({
          reference: readingReference,
          language: language.language
        }))
        .sort(
          (a, b) =>
            this.runicOrder.indexOf(a.reference) -
            this.runicOrder.indexOf(b.referece)
        )
        .sort(
          (a, b) =>
            this.readingReferences.indexOf(a.language) -
            this.readingReferences.indexOf(b.language)
        );
    },
    getReadingTeiTextByReference(inscription, reference) {
      return this.getTeiTextByReference({
        texts: inscription.readings,
        reference
      });
    },
    getFVNTeiTextByReference(inscription, reference) {
      return this.getTeiTextByReference({
        texts: inscription.interpretations,
        language: "FVN",
        reference
      });
    },
    getInterpretationTeiTextByReferenceAndLanguage(
      inscription,
      reference,
      language
    ) {
      return this.getTeiTextByReference({
        texts: inscription.interpretations,
        language: language,
        reference
      });
    },
    /**
     * Return the teiText value of the text with the matching reference or readingReference value.
     * References are P, Q, R, et c.
     */
    getTeiTextByReference({ texts, language, reference }) {
      if (!texts || texts.length == 0) {
        return "";
      }
      for (let text of texts) {
        if (!language || language === text.language.language) {
          let currentReference = text.reference || text.readingReference;
          if (currentReference == reference) {
            return text.teiText;
          }
        }
      }
      return "";
    }
  }
};
</script>
