var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signa-list-in-list"},[_c('BaseList',{staticClass:"signas-list",attrs:{"list-query":_vm.signa,"filter":signum => !_vm.onlyPrimary || signum.inscriptions.length > 0,"locale-section":"pages.inscriptions"},scopedSlots:_vm._u([{key:"element",fn:function({
        element: { signum2, inscriptions }
      }){return [_c('router-link',{attrs:{"to":{
          name: 'inscription',
          params: { id: inscriptions[0].inscription.id }
        }}},[_vm._v(_vm._s(_vm.signum1)+" "+_vm._s(signum2))]),(inscriptions[0])?_vm._l((_vm.referencesAndLanguages(inscriptions[0].inscription)),function(item){return _c('TeiWordCountWarning',{key:item.reference + item.language,attrs:{"tei-primary":_vm.getReadingTeiTextByReference(
              inscriptions[0].inscription,
              item.reference
            ),"tei-reference":_vm.getInterpretationTeiTextByReferenceAndLanguage(
              inscriptions[0].inscription,
              item.reference,
              item.language
            ),"reference-key":item.reference + '/' + item.language}})}):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }